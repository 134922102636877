var generic = generic || {};
var site = site || {};

site.drupalForm = {
  initFormbuilder: function () {
    $('.form-builder-container').each(function (i, obj) {
      var fbfContainerNode = $(obj);
      var fbfFormNode = fbfContainerNode.find('form');
      var fbfSubmitNode = fbfContainerNode.find("input[type='submit']");
      var fbfProgressNode = fbfContainerNode.find('span.progress');
      var fbfSuccessNode = fbfContainerNode.find('span.success');
      var fbfInputNode = fbfContainerNode.find('input.email-signup');
      var unsubscribeUrl = $('.js-email-signup').data('unsubscribe');
      var emailOptStatusNode = $('#address-info-newsletter');
      var form_id = fbfFormNode.find('input[name=form_id]').val();
      fbfFormNode.on('submit', function (submitEvt) {
        submitEvt.preventDefault();
        fbfProgressNode.removeClass('hidden');
        errorMsg = '';
        showErrors = false;
        pass_required = false;
        var formSerial = $(this).serializeArray();
        $.each(formSerial, function (i, val) {
          val.value = $.trim(val.value).replace(/\r\n|\r|\n/g, '<br>').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
        });
        formSerial = $.param(formSerial).replace(/\+/g, ' ');
        var paramArr = formSerial.split('&'),
          paramStr = '';
        origStr = '';
        $.each(paramArr, function (index) {
          initial = this.split('=')[0].replace('form%5B', '');
          initial = initial.replace('%5D', '');
          initial = decodeURI(initial);
          initial = initial.replace(/\[.*\]/, '');
          paramStr += '"' + initial + '":"' + decodeURIComponent(this.split('=')[1]) + '",';
          origStr += '"' + this.split('=')[0] + '":"' + this.split('=')[1] + '",';
        });
        var params = Function('return ' + '(' + '{' + paramStr.substring(0, paramStr.length - 1).replace(/undefined/gi, '').replace(/%40/gi, '@') + '}' + ')')();
        var origParams = Function('return ' + '(' + '{' + origStr.substring(0, origStr.length - 1).replace(/undefined/gi, '').replace(/%40/gi, '@') + '}' + ')')();
        if (params['_SUBMIT'] == 'contact_us') {
          params['suppress_conf_email'] = 1;
        }

        var iframe_url = Drupal.settings.basePath + 'elc_form_thankyou/' + Drupal.settings.elc_form[form_id].nid + '/' + Drupal.settings.elc_form[form_id].vid;

        var version = parseFloat($.fn.$);
        $(this).find('input, select, textarea').each(function () {
          if (version < 1.6) {
            classes = $(this).attr('class').split(' ');
          } else {
            classes = $(this).prop('class').split(' ');
          }
          var is_checkbox = false;
          form_field = this;
          var field_value = $(form_field).val().replace(/^\s+|\s+$/g, '');
          $(form_field).removeClass('label-error');
          $(form_field).removeClass('error');
          $.each(classes.reverse(), function (key, value) {
            switch (value) {
              case 'required':
                if ($(form_field).attr('type') == 'checkbox') {
                  is_checkbox = true;
                }
                var is_checked = (version < 1.6) ? $(form_field).attr('checked') : $(form_field).prop('checked');
                if (form_builder_required(field_value) === false || (is_checkbox === true && is_checked === false) || ($(form_field).attr('name') === 'form[COUNTRY]' && form_builder_check_alphabets(field_value) === false)) {
                  showErrors = true;
                  field = $(form_field).attr('name').replace('form[', '');
                  field = field.replace(']', '');
                  if (is_checkbox == true) {
                    field = field.replace(/\[.*\]/, '');
                  }
                  if (typeof Drupal.settings.elc_form[form_id].validation_messages[field]['required'] != 'undefined') {
                    errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['required'] + '<br>';
                  }
                  pass_required = false;
                  $(form_field).prev().addClass('label-error');
                  $(form_field).addClass('error');
                  $(form_field).parents('.resizable-textarea').prev().addClass('label-error');
                } else {
                  $(form_field).prev().removeClass('label-error');
                  $(form_field).removeClass('error');
                  $(form_field).parents('.resizable-textarea').prev().removeClass('label-error');
                  pass_required = true;
                }
                break;
              case 'email_format':
                if (pass_required == true) {
                  if (form_builder_email_format(field_value) == false) {
                    showErrors = true;
                    field = $(form_field).attr('name').replace('form[', '', fbfContainerNode);
                    field = field.replace(']', '');
                    if (typeof Drupal.settings.elc_form[form_id].validation_messages[field]['email_format'] != 'undefined') {
                      errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['email_format'] + '<br>';
                    }
                    $(form_field).prev().addClass('label-error');
                    $(form_field).addClass('error');
                  }
                }
                break;
              case 'email_confirm':
                if (pass_required == true) {
                  var related_field = $('input[parent=' + $(form_field).attr('related') + ']', fbfContainerNode);
                  if (form_builder_confirm_email(field_value, $(related_field).val().replace(/^\s+|\s+$/g, '')) == false) {
                    showErrors = true;
                    field = $(form_field).attr('name').replace('form[', '');
                    field = field.replace(']', '');
                    errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['email_confirm'] + '<br>';
                    $(form_field).prev().addClass('label-error');
                    $(form_field).addClass('error');
                  }
                }
                break;
              case 'number_check':
                if (pass_required == true) {
                  if (form_builder_number_format(field_value) == false) {
                    showErrors = true;
                    field = $(form_field).attr('name').replace('form[', '');
                    field = field.replace(']', '');
                    errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['required'] + '<br>';
                    $(form_field).prev().addClass('label-error');
                    $(form_field).addClass('error');
                  }
                }
                break;
            }
          });
        });
        if (typeof Drupal.settings.elc_form[form_id].age_value != 'undefined') {
          if (form_builder_age_check(params, Drupal.settings.elc_form[form_id].age_value) == false) {
            showErrors = true;
            errorMsg += Drupal.settings.elc_form[form_id].validation_messages['AGE']['required'] + '<br>';
            $('#edit-form-age-year, #edit-form-age-month, #edit-form-age-day', fbfContainerNode).addClass('error');
          }
        }
        var form_error_class = '.form-error-' + form_id;
        if (showErrors == true) {
          $(form_error_class).html(errorMsg);
          $(form_error_class).show();
        } else {
          $(form_error_class).hide();
          fbfSubmitNode.addClass('hidden');
          if (params._SUBMIT == 'pass-thru') {
            if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
              var send_url = Drupal.settings.elc_form[form_id].success_page + '?';
              if (Drupal.settings.elc_form[form_id].use_iframe == true) {
                send_url = iframe_url;
              }
              $('.pass-thru', fbfContainerNode).each(function () {
                send_url += $(this).attr('name').replace('form[', '').replace(']', '') + '=' + $(this).val();
              });
              window.location.href = send_url;
            }
          }
          var method = 'rpc.form';
          if (typeof params._SUBMIT != 'undefined') {
            var _SUBMIT = typeof unsubscribeUrl !== 'undefined' ? unsubscribeUrl : params._SUBMIT;
            delete params._SUBMIT;
          }
          if (typeof _SUBMIT != 'undefined') {
            var pg_reqs = Drupal.settings.perlgem.pg_reqs;
            var strLen = pg_reqs.length;
            pg_reqs = pg_reqs.slice(0, strLen - 1);
            if (_SUBMIT != 'contact+us' && _SUBMIT != 'contact_us') {
              $.ajax({
                type: 'POST',
                url: '/' + _SUBMIT + '?' + pg_reqs,
                dataType: 'json',
                data: params,
                success: function (val) {
                  if (val.success == 0 && params.UNSUB_ERROR != 'undefined') {
                    fbfContainerNode.html(params.UNSUB_ERROR);
                    if (_SUBMIT === 'email_optout.logic') {
                      fbfSubmitNode.removeClass('hidden');
                      $("input[name='form[Email_Address]']").addClass('error');
                      $(form_error_class).html(Drupal.settings.elc_form[form_id].validation_messages['Email_Address']['required']);
                      $(form_error_class).show();
                    }
                    return false;
                  }
                  $("input[name='form[Email_Address]']").removeClass('error');
                  if (typeof Drupal.settings.elc_form[form_id].success != 'undefined') {
                    fbfContainerNode.html(Drupal.settings.elc_form[form_id].success);
                  }
                  if (typeof Drupal.settings.elc_form[form_id].ajax != 'undefined') {
                    if (typeof Drupal.settings.elc_form[form_id] != 'undefined') {
                      params['mapping'] = Drupal.settings.elc_form[form_id];
                    }
                    $.ajax({
                      type: 'POST',
                      url: '/elc_form_submit/' + Drupal.settings.elc_form[form_id].nid + '/' + Drupal.settings.elc_form[form_id].vid,
                      dataType: 'json',
                      data: params,
                      success: function (val) {
                        if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
                          var send_url = Drupal.settings.elc_form[form_id].success_page + '?';
                          if (Drupal.settings.elc_form[form_id].use_iframe == true) {
                            send_url = iframe_url;
                          }
                          $('.pass-thru', fbfContainerNode).each(function () {
                            send_url += $(this).attr('name').replace('form[', '').replace(']', '') + '=' + $(this).val();
                          });
                          window.location.href = send_url;
                        }
                      }
                    });
                  } else {
                    if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
                      var send_url = Drupal.settings.elc_form[form_id].success_page + '?';
                      if (Drupal.settings.elc_form[form_id].use_iframe == true) {
                        send_url = iframe_url;
                      }
                      $('.pass-thru', fbfContainerNode).each(function () {
                        send_url += $(this).attr('name').replace('form[', '').replace(']', '') + '=' + $(this).val();
                      });
                      window.location.href = send_url;
                    }
                  }
                }
              });
            } else { // contact-us email
              if (typeof Drupal.settings.elc_form[form_id].success != 'undefined') {
                fbfContainerNode.html(Drupal.settings.elc_form[form_id].success);
              }
              if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
                var send_url = Drupal.settings.elc_form[form_id].success_page + '?';
                if (Drupal.settings.elc_form[form_id].use_iframe == true) {
                  send_url = Drupal.settings.basePath + 'elc_form_thankyou/' + Drupal.settings.elc_form[form_id].nid;
                }
                $('.pass-thru', fbfContainerNode).each(function () {
                  send_url = iframe_url;
                });
                window.location.href = send_url;
              }
              if (typeof Drupal.settings.elc_form[form_id] != 'undefined') {
                params['mapping'] = Drupal.settings.elc_form[form_id];
              }
              $.ajax({
                type: 'POST',
                url: '/elc_form_submit/' + Drupal.settings.elc_form[form_id].nid + '/' + Drupal.settings.elc_form[form_id].vid,
                dataType: 'json',
                data: params,
                success: function (val) {
                  if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
                    var send_url = Drupal.settings.elc_form[form_id].success_page + '?';
                    if (Drupal.settings.elc_form[form_id].use_iframe == true) {
                      send_url = iframe_url;
                    }
                    $('.pass-thru', fbfContainerNode).each(function () {
                      send_url += $(this).attr('name').replace('form[', '').replace(']', '') + '=' + $(this).val();
                    });
                    window.location.href = send_url;
                  }
                }
              });
            }
          }
        }
      });
    });
  }
};

(function ($, Drupal, site) {
  Drupal.behaviors.elc_form = {
    attach: function (context, settings) {
      site.drupalForm.initFormbuilder();
    }
  };
})(jQuery, Drupal, site);

function form_builder_check_alphabets(value) {
  var letters = /^[A-Za-z\s]+$/;
  return (value.match(letters)) ? true : false;
}
